// style.scss
@import "../../sass/helpers/variables";
@import "../../sass/helpers/mixins";
// Center the login form within the dialog
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
  .nav-tabs {
    border-bottom: none;
  }
}

.login-form {
  background: white;
  padding: 20px;
  border-radius: 8px;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  min-width: 400px;
  // height: 480px;
  @media (max-width: 575px) {
    min-width: 100%;
  }
}

.login-header {
  text-align: center;
  margin-bottom: 20px;
  h2 {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    text-transform: capitalize;
    font-family: "Rubik", sans-serif;
    color: #1c263a;
  }
}

.login-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  .nav-item {
    flex: 1;
    text-align: center;

    .nav-link {
      display: block;
      padding: 10px;
      cursor: pointer;
      color: #120219;
      font-weight: bold;
      border: none;
      &.active {
        background-color: #120219;
        color: white;
        border-radius: 4px;
      }
    }
  }
}

.contact-form {
  margin-top: 20px;
}

.form-field {
  margin-bottom: 15px;

  label {
    // font-family: "Cormorant";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-transform: capitalize;
    color: #1c263a;
    margin-bottom: 10px;
  }

  input {
    width: 100%;
    margin-bottom: 0px;
    padding-left: 10px;
    height: 35px;
    border-radius: 5px;
    border: none;
    background: #f2f2f2;
  }
}

.forgot {
  display: block;
  margin: 15px 0;
  text-align: center;
  font-size: 14px;
  color: $theme-primary-color;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.eye-button {
  border: none;
  position: absolute;
  top: -10;
  // left: 0;
  right: 10px;
  background-color: transparent;
  padding-top: 4px;
}
