@import "tailwind";
/* 1.0 Common css  */
html {
  font-size: $base-font-size + px;
}

:root {
  scroll-behavior: inherit;
  --custom-purple: #6552b7;
  --custom-yellow: #ffab00;
  --custom-red: #fc424a;
  --custom-blue: #0090e7;
  --custom-green: #00d25b;
}

body {
  font-family: $base-font;
  color: $body-color;
  background-color: $white;
  font-size: 16px;
  font-size: calc-rem-value(16);
  overflow-x: hidden;

  @include media-query(767px) {
    font-size: 14px;
  }
}

body.dark-page {
  background-color: $body-bg-color;
}

p {
  font-size: 16px;
  color: $body-color;
  line-height: 1.5em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $heading-color;
  font-family: $base-font;
  font-weight: 400;
}

ul {
  padding-left: 0;
  margin: 0;
  list-style: none;
}

a {
  text-decoration: none;
  @include transition-time(0.2s);
}

a:hover {
  text-decoration: none;
}

img {
  max-width: 100%;
}

.section-fluid {
  padding: 120px 65px;

  @media (max-width: 991px) {
    padding: 90px 30px;
  }

  @media (max-width: 575px) {
    padding: 80px 0px;
  }
}

.container {
  @media (min-width: 1400px) {
    max-width: 1300px;
  }
}

.hidden {
  display: none;
}

.sr-only {
  display: none !important;
  opacity: 0;
  visibility: hidden;
}

/* 1.2 Global Elements */
.page-wrap {
  position: relative;
  overflow: hidden;
}

.App {
  position: relative;
  overflow: hidden;
}

.page-wrapper {
  @include media-query(991px) {
    overflow: hidden;
  }
}

.wow {
  visibility: hidden;
}

.fi:before {
  margin: 0;
}

.section-bg {
  background: #f5f5f5 !important;
}

.separator-padding {
  padding: 120px 0;

  @include media-query(991px) {
    padding: 90px 0;
  }

  @include media-query(767px) {
    padding: 80px 0;
  }

  @include media-query(575px) {
    padding: 60px 0;
  }
}

.sm-separator-padding {
  padding: 60px 0;

  @include media-query(991px) {
    padding: 50px 0;
  }

  @include media-query(767px) {
    padding: 40px 0;
  }

  @include media-query(575px) {
    padding: 30px 0;
  }
}

.pt-120 {
  padding-top: 120px;

  @include media-query(991px) {
    padding-top: 90px;
  }

  @include media-query(767px) {
    padding-top: 80px;
  }

  @include media-query(575px) {
    padding-top: 60px;
  }
}

.pb-120 {
  padding-bottom: 120px;

  @include media-query(991px) {
    padding-bottom: 90px;
  }

  @include media-query(767px) {
    padding-bottom: 80px;
  }

  @include media-query(575px) {
    padding-bottom: 60px;
  }
}
.contactus-box{
  width: 40%;

  align-items: center;
  margin: auto;
  margin-top: 50px;
}
.privacy-box p{
  color: black;
  font-size: 14px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  padding-top: 10px;
}
.privacy-box h6{
padding-top: 20px;
padding-bottom: 5px;
}
.privacy-box{

  height: auto;
  padding-bottom: 50px;
  padding-top: 50px;
  width: 70%;
  margin: auto;

}

.conatctno-found{
  display: flex;
  width: 40%;
margin: auto;
margin-top: 50px;

}
.conatctno-found div:first-child{
  width: 170px;
  font-size: 20px;
  font-weight:200;
}
.conatct-found{
  display: flex;
  width: 40%;
margin: auto;
gap: 50px;
justify-content: space-between;
margin-top: 50px;
}
.conatct-found div:first-child{

  width: 250px;
  font-size: 20px;
  font-weight:200;
}
/*** contact form error handling ***/
.contact-activation {
  .error-handling-messages {
    width: 100% !important;
    margin-top: 15px !important;
  }

  label.error {
    color: red;
    font-size: 13px;
    font-weight: normal;
    margin: 5px 0 0 0;
    text-align: left;
    display: block;
    margin-bottom: 10px;
  }

  #c-loader,
  #loader {
    display: none;
    margin-top: 10px;

    i {
      font-size: 30px;
      font-size: calc-rem-value(30);
      color: $theme-primary-color;
      display: inline-block;
      -webkit-animation: rotating linear 2s infinite;
      animation: rotating linear 2s infinite;
    }
  }

  #success,
  #c-success,
  #c-error,
  #error {
    width: 100%;
    color: $white;
    padding: 5px 10px;
    font-size: 16px;
    text-align: center;
    display: none;

    @include media-query(767px) {
      font-size: 15px;
    }
  }

  #c-success,
  #success {
    background-color: lighten(green, 5%);
    border-left: 5px solid green;
    margin-bottom: 5px;
  }

  #c-error,
  #error {
    background-color: lighten(red, 5%);
    border-left: 5px solid red;
  }
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.rotating {
  -webkit-animation: rotating 5s alternate infinite;
  animation: rotating 5s alternate infinite;
}

/*** back to top **/
.back-btn {
  background-color: $theme-primary-color;
  width: 45px;
  height: 45px;
  line-height: 45px;
  border: 4px solid darken($theme-primary-color, 2);
  border-radius: 3px;
  text-align: center;
  display: none;
  position: fixed;
  z-index: 999;
  right: 15px;
  bottom: 15px;
  @include transition-time(0.2s);

  @include media-query(991px) {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }

  &:hover {
    background-color: $theme-primary-color;
  }
}

.back-btn i {
  font-size: 18px;
  font-size: calc-rem-value(18);
  color: #fff;
  position: absolute;
  left: 39%;
  top: -12%;
  @include center-by-translating();
  transform: rotate(268deg);

  @include media-query(991px) {
    left: 32%;
    top: -15%;
  }
}

/** for popup image ***/
.mfp-wrap {
  background-color: transparentize($black, 0.1);
  z-index: 99999;
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}

/*** for fancybox video ***/
.fancybox-overlay {
  background: rgba(0, 0, 0, 0.9);
  z-index: 9999 !important;
}

.fancybox-wrap {
  z-index: 99999 !important;
}

// datepicker-design

.ui-widget.ui-widget-content {
  box-shadow: 0px 1px 13.5px 1.5px rgba(46, 49, 108, 0.08);
  border: none;
  padding: 10px;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-button,
html .ui-button.ui-state-disabled:hover,
html .ui-button.ui-state-disabled:active {
  border: 1px solid #e3e3e3;
  background: #f9f9f9;
  font-weight: normal;
  color: #454545;
  text-align: center;
}

.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
  border: 1px solid #e3d9d9;
  background: #7f91a6;
  color: #ffffff;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover {
  border: 1px solid $theme-primary-color;
  background: $theme-primary-color;
  font-weight: normal;
  color: #ffffff;
}

body.dark-page {
  .mouse-circle {
    background-color: $white;
  }
  .mouse-circle-follow {
    border-color: $white;
  }
}

// sticky design

.sticky {
  position: fixed !important;
  background: $white;
  top: 0;
  left: 0;
  width: 100%;
  transition: all 0.5s ease;
  animation: smoothScroll 1s forwards;
  box-shadow: 0 -5px 15px #086ad84a;
  z-index: 999;
}

@keyframes smoothScroll {
  0% {
    transform: translateY(-142px);
  }

  100% {
    transform: translateY(0px);
  }
}

.header-style-1 .sticky {
  @media (max-width: 991px) {
    padding: 15px;
  }

  @media (max-width: 575px) {
    padding: 15px 0;
  }

  .mean-container .mean-nav {
    margin-top: 15px;
  }
}

.header-style-3 .sticky {
  @media (max-width: 991px) {
    padding: 15px;
  }
  @media (max-width: 575px) {
    padding: 15px 0;
  }
}

/*mobal-subscribe*/

.cart-quickview {
  max-width: 1120px;
  margin: 200px auto;
  // z-index: 999999 !important;
  .MuiDialog-paper {
    min-height: 560px !important;
  }
  .fullscreen {
    .image-gallery-image {
      height: calc(100vh - 100px) !important;
      object-fit: contain;
    }
  }
  .image-gallery-slide {
    .image-gallery-image {
      height: 300px;
      object-fit: contain;
    }
  }
  .image-gallery-thumbnail {
    &:hover {
      border: 2px solid $theme-primary-color;
    }
    .image-gallery-thumbnail-image {
      height: 80px;
      object-fit: contain;
      background-color: $theme-primary-color2;
    }
  }
  .image-gallery-thumbnails-wrapper {
    .active {
      border: 2px solid $theme-primary-color;
    }
  }
  .image-gallery-right-nav {
    background-color: $theme-primary-color;
    padding: 4px;
    border-radius: 6px;
    .image-gallery-svg {
      height: 35px;
      width: 35px;
    }
  }
  .image-gallery-left-nav {
    background-color: $theme-primary-color;
    padding: 4px;
    border-radius: 6px;
    .image-gallery-svg {
      height: 35px;
      width: 35px;
    }
  }
  .image-gallery-icon {
    &:focus {
      outline: none;
    }
  }
  @media (max-width: 991px) {
    max-width: 500px;
  }

  .modal-body {
    // padding: 20px;

    @media (max-width: 991px) {
      padding: 15px;
    }
  }

  .modal-product {
    img {
      width: 100%;
    }
  }

  .product-details .product-single-content {
    h5 {
      margin-bottom: 5px;
    }

    .pro-single-btn .quantity {
      @media (max-width: 991px) {
        margin-bottom: 20px;
      }
    }
  }

  button.btn-close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    background: $theme-primary-color;
    text-align: center;
    font-size: 24px;
    border: none;
    color: $white;
    z-index: 9;
    transition: all 0.4s ease-in-out;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.single-product {
  .product-single-image {
    position: relative;
    .tag-wrap {
      position: absolute;
      top: 10px;
      left: 10px;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      .tag {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
        color: $white;
        border-radius: 5px;
        display: inline-block;
        padding: 5px 10px;
        background: $theme-primary-color;

        &.new {
          background: $theme-primary-color;
        }

        &.sale {
          background: #ff8080;
        }

        &.trending {
          background: $theme-primary-color2;
        }

        &.discount {
          background: #ff8080;
        }

        &.red {
          background: $theme-primary-color3;
        }
      }
    }

    .info-wrap {
      position: absolute;
      list-style: none;
      right: 15px;
      top: 15px;
      transition: all 0.5s ease-in-out;
      opacity: 0;
      visibility: hidden;

      li {
        margin-bottom: 5px;
        margin-right: -50px;

        &:first-child {
          transition: all 0.3s ease-in-out;
        }

        &:nth-child(2) {
          transition: all 0.4s ease-in-out;
        }

        &:last-child {
          margin-bottom: 0;
          transition: all 0.5s ease-in-out;
        }

        &:hover {
          a,
          button {
            background: $theme-primary-color;
            color: $white;
          }
        }

        a,
        button {
          width: 40px;
          height: 40px;
          background: #fafafa;
          color: $text-color;
          line-height: 40px;
          display: block;
          text-align: center;
          transition: all 0.3s ease-in-out;
          border: 1px solid $border-color-s2;
          border-radius: 50%;

          i {
            font-size: 20px;
            line-height: unset;
          }
        }

        &:last-child {
          button {
            line-height: 35px;
          }
        }
      }
    }
  }
  .fullscreen {
    .image-gallery-image {
      height: calc(100vh - 100px) !important;
      object-fit: contain;
    }
  }
  .image-gallery-slide {
    .image-gallery-image {
      height: 300px;
      object-fit: contain;
    }
  }
  .image-gallery-thumbnail {
    &:hover {
      border: 2px solid $theme-primary-color;
    }
    .image-gallery-thumbnail-image {
      height: 80px;
      object-fit: contain;
      background-color: $theme-primary-color2;
    }
  }
  .image-gallery-thumbnails-wrapper {
    .active {
      border: 2px solid $theme-primary-color;
    }
  }
  .image-gallery-right-nav {
    background-color: $theme-primary-color;
    padding: 4px;
    border-radius: 6px;
    .image-gallery-svg {
      height: 35px;
      width: 35px;
    }
  }
  .image-gallery-left-nav {
    background-color: $theme-primary-color;
    padding: 4px;
    border-radius: 6px;
    .image-gallery-svg {
      height: 35px;
      width: 35px;
    }
  }
  .image-gallery-icon {
    &:focus {
      outline: none;
    }
  }
  @media (max-width: 991px) {
    max-width: 500px;
  }
}
.css-1t4vnk2-MuiDialogContent-root {
  padding: 0px !important;
}

.modal {
  z-index: 9999;
}
.subscribe-dialog,
.MuiDialog-paper {
  max-width: 1200px !important;
  // min-height: 480px !important;
  z-index: 9999;
  border-radius: 20px !important;
  // background-color: red !important;

  @media (max-width: 767px) {
    max-width: 500px;
  }
}

.errorMessage {
  color: red;
  font-size: 12px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.responsive-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  @include media-query(991px) {
  }
  @include media-query(767px) {
  }
  @include media-query(575px) {
    flex-direction: column;
    gap: 10px;
  }
}

.date-picker-input {
  input {
    width: 322px !important;
  }
  @include media-query(991px) {
  }
  @include media-query(767px) {
    input {
      width: 100% !important;
    }
  }
  @include media-query(575px) {
    input {
      width: 100% !important;
    }
  }
}
.sm-container {
  padding: 0px 80px;
  @include media-query(991px) {
    padding: 0px 60px;
  }
  @include media-query(767px) {
    padding: 0px 40px;
  }
  @include media-query(575px) {
    padding: 0px 20px;
  }
}
.product-card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  div:nth-child(1) {
    width: 50%;
  }
}
.old-price,
.present-price {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.present-price {
  color: $dark-gray;
  padding-right: 5px;
}

.old-price {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: $text-color;
}

.cart-btn {
  width: 40px;
  height: 40px;
  line-height: 40px;
  position: absolute;
  right: 0;
  top: -5px;
  background: #f8f8f8;
  text-align: center;
  color: $theme-primary-color2;
  border-radius: 50%;
  border: 0;
  transition: all 0.3s;

  &:hover {
    background: $theme-primary-color;
    color: $white;
  }
}
.cart-btn-full {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 40px;
  height: 40px;
  line-height: 40px;
  background: $theme-primary-color;
  text-align: center;
  color: #f8f8f8;
  border-radius: 20px;
  border: 0.5px solid $theme-primary-color;
  transition: all 0.3s;

  &:hover {
    background: $theme-primary-color2;
    color: $white;
    border: none;
  }
}
.buy-now-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  min-width: 50px;
  height: 40px;
  background: $theme-primary-color2;
  text-align: center;
  color: #f8f8f8;
  border-radius: 20px;
  border: 0.5px solid $theme-primary-color2;
  transition: all 0.3s;
  padding: 0px 10px;

  &:hover {
    background: $theme-primary-color;
    color: $white;
    border: 0.5px solid $theme-primary-color;
  }
}
.incQtybutton {
  width: 60px;
  height: 40px;
  line-height: 40px;
  background: $theme-primary-color;
  text-align: center;
  color: #f8f8f8;
  border-radius: 20px;
  border: 0.5px solid $theme-primary-color;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: $theme-primary-color2;
    color: $white;
    border: none;
  }
}
.decQtybutton {
  width: 60px;
  height: 40px;
  line-height: 40px;
  background: $theme-primary-color;
  text-align: center;
  color: #f8f8f8;
  border-radius: 20px;
  border: 0.5px solid $theme-primary-color;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    path {
      fill: $white;
    }
  }

  &:hover {
    background: $theme-primary-color2;
    color: $white;
    border: none;
    svg {
      path {
        fill: $white;
      }
    }
  }
}
.flex-justify-center-items-center {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.gap {
  gap: 10px;
}
.w-full {
  width: 100%;
}
.h-full {
  height: 100%;
}

.product-single-img {
  img {
    height: 380px;
  }
}

.link-button {
  background-color: transparent;
  border: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  color: #1c263a;
  &:hover {
    color: $theme-primary-color;
  }
}
.resend-button {
  background-color: transparent;
  padding: 6px 20px;
  border: 0.2px solid transparent;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  text-transform: capitalize;
  color: #5d666f;
  transition: all ease-in 30ms;
  &:hover {
    color: $theme-primary-color;
    border-color: $theme-primary-color;
  }
  &:disabled {
    cursor: not-allowed;
  }
}

.grid-4 {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(4, 1fr);
  @include media-query(1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @include media-query(991px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include media-query(767px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include media-query(575px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
.grid-3 {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(3, 1fr);
  @include media-query(1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @include media-query(991px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include media-query(767px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include media-query(575px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
.grid-2 {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(2, 1fr);
  @include media-query(1200px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @include media-query(991px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @include media-query(767px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @include media-query(575px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.css-yx0nvq-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: $theme-primary-color2 !important;
  color: $white;
}

.css-yx0nvq-MuiButtonBase-root-MuiPaginationItem-root {
  border-radius: 100px;
  &:hover {
    background-color: $theme-primary-color2 !important;
    color: $white;
  }
}

.css-rppfq7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: $theme-primary-color2 !important;
  color: $white;
  &:hover {
  }
}
.css-rppfq7-MuiButtonBase-root-MuiPaginationItem-root {
  &:hover {
    background-color: $theme-primary-color2 !important;
    color: $white;
  }
}

.h-400 {
  height: 400px;
}
.min-h-400 {
  min-height: 400px;
}
.h-300 {
  height: 300px;
}
.min-h-300 {
  min-height: 300px;
}

/* Target the specific div using an ID or class */
.scrollable-div {
  overflow-y: scroll;
  overflow-x: hidden;
}

/* Scrollbar styling for WebKit browsers */
.scrollable-div::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

.scrollable-div::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the scrollbar track */
  border-radius: 10px;
}

.scrollable-div::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 10px;
  border: 2px solid #f1f1f1; /* Adds padding inside thumb */
}

.scrollable-div::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Change the color on hover */
}

/* Optional: Scrollbar styling for Firefox */
.scrollable-div {
  scrollbar-width: thin; /* Thin scrollbar */
  scrollbar-color: #888 #f1f1f1; /* Thumb and track colors */
}

// .product-image {
//   position: relative;
//   transition: all 0.3s ease-in-out;
//   overflow: hidden;
//   padding-top: 15px;
//   border-radius: 10px;
//   border: 1px solid #e9ecef;
//   text-align: center;

//   img {
//     transform: scale(1);
//     transition: all 0.3s;
//     width: 350px;
//     height: 350px;
//     object-fit: cover;
//   }
// }

.shimmer-card:not(:first-child) {
  margin-top: 0px;
}

.btn-close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  background: $theme-primary-color;
  text-align: center;
  font-size: 24px;
  border: none;
  color: $white;
  z-index: 9;
  transition: all 0.4s ease-in-out;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Toastify__toast {
  border-radius: 20px;
  margin-bottom: 10px;
}

.coupon-area {
  // margin-bottom: 80px;

  .coupon-box {
    background: #f2f2f2;
    padding: 20px;

    p {
      margin-bottom: 0;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 29px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      i {
        color: $theme-primary-color;
        margin-right: 10px;
      }

      span {
        display: inline-block;
        color: $theme-primary-color;
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }

  .coupon-form p {
    margin-bottom: 15px;
    color: $text-light-color;
  }

  .coupon-form {
    padding: 20px;
  }

  .coupon-form span {
    margin-bottom: 20px;
    display: block;
    color: $text-light-color;
  }

  .input-wrap {
    position: relative;
  }

  .coupon-form input {
    width: 100%;
    height: 50px;
    border: 1px solid #ebebeb;
    padding-left: 20px;
    border-radius: 0;
  }

  .coupon-form input:focus {
    outline: none;
    box-shadow: none;
  }

  .coupon-form button {
    position: absolute;
    right: 0;
    top: 0;
    height: 50px;
    background: $theme-primary-color;
    color: #fff;
    width: 108px;
    border: none;
    outline: none;
    box-shadow: none;
    cursor: pointer;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.search-container {
  @media (max-width: 991px) {
    // padding-bottom: 20px;
  }

  .search-box {
    background: #edf2f4;
    border-radius: 5px;
    display: flex;
    border-radius: 10px;
    position: relative;
    z-index: 999;
    align-items: center;

    .category {
      display: inline-block;
      width: 170px;
      opacity: 1;
      position: relative;

      &:before {
        position: absolute;
        right: 0;
        width: 1px;
        height: 20px;
        content: "";
        background: #7c7c7c;
        top: 50%;
        transform: translateY(-50%);
      }

      select {
        border: 0;
        height: 55px;
        line-height: 40px;
        padding-left: 20px;
        padding-right: 10px;

        &:after {
          right: auto;
          left: 25px;
          width: 8px;
          height: 8px;
          top: 27px;
        }

        &:before {
          position: absolute;
          left: 10px;
          top: 8px;
          width: 40px;
          height: 40px;
          content: "";
          background: $white;
          border-radius: 50%;
        }
      }

      .form-control {
        font-family: $base-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0.42px;
        text-transform: uppercase;
        width: 170px;

        @media (max-width: 575px) {
          width: 150px;
          font-size: 12px;
        }

        span.current {
          margin-top: 3px;
          display: block;
        }
      }
    }

    .search-box {
      width: calc(103% - 200px);
      display: inline-block;
    }

    .form-control {
      background-color: transparent;
      // border: 0;
      border-radius: 10px 0px 0px 10px;
      font-weight: 400;
      font-size: 15px;
      color: $text-color;

      &:focus {
        box-shadow: none;
      }
    }

    .MuiFormControl-root {
      min-width: 100%;
      margin: 0;
      padding-left: 30px;

      .MuiSelect-select {
        padding: 0;
        padding-right: 30px;
        height: 45px;
        line-height: 45px;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        font-family: $base-font;
        text-transform: uppercase;
      }

      .MuiOutlinedInput-notchedOutline {
        border: 0;
      }
    }

    .search-btn {
      border: 0;
      width: 50px;
      height: 51px;
      // margin-right: -2px;
      background: none;
      background-color: $theme-primary-color;
      border-radius: 0px 10px 10px 0px;

      @media (max-width: 1199px) {
        // margin-right: -6px;
      }

      @media (max-width: 575px) {
        // width: 30px;
      }

      img {
        max-width: 20px;
      }
    }
  }
}
.search-img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 100px;
  margin-right: 10px;
}

.address {
  border: 1px solid #d3d3d3;
  background-color: transparent;
  cursor: pointer;
  border-radius: 20px;
  p {
    margin: 4px;
    padding: 0px;
  }
  input {
    height: 20px !important;
    width: 20px !important;
    @include media-query(991px) {
      height: 20px !important;
      width: 20px !important;
    }
    @include media-query(767px) {
      height: 20px !important;
      width: 20px !important;
    }
    @include media-query(575px) {
      height: 20px !important;
      width: 20px !important;
    }
  }
  &:hover {
    border: 1px solid $theme-primary-color;
  }
}
.selected-address {
  cursor: pointer;
  border: 1px solid $theme-primary-color;
  border-radius: 20px;
  background-color: #f2f2f2;
  p {
    margin: 4px;
    padding: 0px;
  }
  input {
    height: 20px !important;
    width: 20px !important;
    @include media-query(991px) {
      height: 20px !important;
      width: 20px !important;
    }
    @include media-query(767px) {
      height: 20px !important;
      width: 20px !important;
    }
    @include media-query(575px) {
      height: 20px !important;
      width: 20px !important;
    }
  }
}

.recomended-section {
  position: relative;
  .slick-prev {
    // box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
    z-index: 99;
    &::before {
      color: $theme-primary-color2;
    }
    background-color: white;
    padding: 10px;
    height: 40px;
    width: 40px;
    top: -20px;
    left: auto; /* Removes left positioning */
    right: 40px; /* Positions it to the right */
  }

  .slick-next {
    // box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
    z-index: 99;
    &::before {
      color: $theme-primary-color2;
    }
    background-color: white;
    padding: 10px;
    height: 40px;
    width: 40px;
    right: 0;
    top: -20px;
  }
}

.bghjHX {
  margin: 0px;
}

.home-banner-wrapper {
  height: 70vh;
  width: 100%;
}

.banner-image {
  aspect-ratio: 16/9;
  height: 70vh;
  width: 95%;
  object-fit: contain;
  background-position: cover;
}

.product-card-title {
  h1,
  h2,
  a {
    width: 300px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    font-size: 1.3rem;
  }
}
.logout-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 45px;
  background-color: transparent;
  padding: 10px 20px;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  transition: all 0.3s;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  i {
    font-size: 24px;
  }
}

.icon-image {
  height: 100px;
  width: 100px;
  object-fit: contain;
  border-radius: 500px;
}
.sm-icon-image {
  height: 50px;
  width: 50px;
  min-height: 50px;
  min-width: 50px;
  max-height: 50px;
  max-width: 50px;
  object-fit: contain;
  border-radius: 500px;
  background-color: #000;
  word-break: break-word;
}
.word-break {
  word-break: break-word;
}
.break-all {
  word-break: break-all;
}
.MuiPagination-ul {
  @include media-query(991px) {
  }
  @include media-query(767px) {
  }
  @include media-query(575px) {
    gap: 2px;
  }
}
.custom-prev,
.custom-next {
  position: absolute;
  top: 50%;
  // right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  @include translatingY();
  width: 35px;
  height: 35px;
  line-height: 31px;
  transition: all 0.3s;
  border-radius: 5px;
  border: 0;
  background: $white;
  z-index: 1;
  font-size: 0;
  border-radius: 50%;
  // box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  @include media-query(991px) {
  }
  @include media-query(767px) {
  }
  @include media-query(575px) {
    position: relative;
    @include translatingY(0%);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }

  svg {
    path {
      color: $theme-primary-color;
    }
  }
  &:disabled {
    cursor: no-drop;
  }
  &:hover {
    background: $theme-primary-color2;
    color: $white;
    svg {
      path {
        color: $white;
      }
    }
  }
}
.custom-prev {
  left: 0;
}
.custom-next {
  right: 0;
}

.selected-route {
  border-bottom: 4px solid $theme-primary-color;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  color: $theme-primary-color !important;
}
.mobile-nav-link-btn {
  display: flex;
  padding: 24px 10px;
  gap: 10px;
  align-items: center;
  width: 100%;
  height: 40px;
  line-height: 40px;
  background: transparent;
  text-align: center;
  color: #f8f8f8;
  border: 2px solid transparent;
  transition: all 0.3s;
  &:hover {
    border-bottom: 2px solid $theme-primary-color2;
  }
}
.mobile-nav-link-btn-active {
  display: flex;
  padding: 24px 10px;
  gap: 10px;
  align-items: center;
  width: 100%;
  height: 40px;
  line-height: 40px;
  background: transparent;
  text-align: center;
  color: #f8f8f8;
  border: 2px solid transparent;
  border-bottom: 2px solid $theme-primary-color2;
  transition: all 0.3s;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
#metis-menu {
  max-height: 50vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.your-orders-card {
  img {
    height: 200px;
    width: 200px;
    object-fit: cover;
  }
}
.google-login-button {
  border-radius: 20px;
  box-sizing: border-box;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: 14px;
  gap: 5px;
  border: 0.2px solid $theme-primary-color;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
}
.google-login-button:hover{
  background-color: #1c263a;
  color: white;
}

.order-box {
  .shimmer-card {
    box-shadow: none;
  }
}

.bg-custom-purple {
  background-color: var(--custom-purple);
}
.bg-custom-yellow {
  background-color: var(--custom-yellow);
}
.bg-custom-red {
  background-color: var(--custom-red);
}
.bg-custom-blue {
  background-color: var(--custom-blue);
}
.bg-custom-green {
  background-color: var(--custom-green);
}
.color-custom-purple {
  color: var(--custom-purple);
}
.color-custom-yellow {
  color: var(--custom-yellow);
}
.color-custom-red {
  color: var(--custom-red);
}
.color-custom-blue {
  color: var(--custom-blue);
}
.color-custom-green {
  color: var(--custom-green);
}
.text-white {
  color: #fafafa;
}
.completed {
  background-color: #00d25b;
  padding: 6px 16px;
  border-radius: 40px;
  color: #fafafa;
}
.failed {
  background-color: #fc424a;
  padding: 6px 16px;
  border-radius: 40px;
  color: #fafafa;
}
.pending {
  background-color: #ffab00;
  padding: 6px 16px;
  border-radius: 40px;
  color: #fafafa;
}
.processing {
  background-color: #3b5998;
  padding: 6px 16px;
  border-radius: 40px;
  color: #fafafa;
}
.dispatched {
  background-color: #0090e7;
  padding: 6px 16px;
  border-radius: 40px;
  color: #fafafa;
}
.delivered {
  background-color: #00d25b;
  padding: 6px 16px;
  border-radius: 40px;
  color: #fafafa;
}
.cancelled {
  background-color: #fc424a;
  padding: 6px 16px;
  border-radius: 40px;
  color: #fafafa;
}
